import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarImg from '../../assets/images/IconsNew/sedan-car-front.png'
import AgegroupImg from '../../assets/images/IconsNew/age-group.png'
import BagImg from '../../assets/images/IconsNew/luggage.png'
import Vehicle1Img from '../../assets/images/Vehi1.jpg'
import '../Summary/style.scss'
import { capitalize, includes, isArray, isEmpty } from 'lodash'
import { BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import moment from 'moment'
import { checkAirportType } from '../../Redux/actions/bookingActions'
import { useNavigate } from 'react-router-dom'




const Vehicle = ({ item }) => {


   console.log({item})

    const { bookingData } = useSelector(state => state.booking)
    const { user, additionalStops } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)

    const navigate = useNavigate()
    const dispatch = useDispatch()



     let emptycheck = 21;

  



    let agentmarkup = 0;
    let adminMarkup = 0
    let tax = 0;
    let total = 0;
    let additionalStop = 0;
    let extras = 0;
    let toll = item?.tollrate ? parseFloat(item?.tollrate) : 0;

    additionalStop = isArray(bookingData?.stops) ? additionalStops ? bookingData?.stops?.length * additionalStops : bookingData?.stops?.length * 20  : 0;

    let rate = bookingData?.type === "hour" ? (parseFloat(item?.rate) * parseFloat(bookingData?.duration)) + additionalStop : parseFloat(item?.rate) + additionalStop

    if (isArray(item?.extraCharges)) {
        item?.extraCharges?.map(extra => {
            if (extra?.rate_type === "percentage") {
                extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
            }
            else {
                extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
            }
        })
    }


    if (user?.role === "agent" && markupLists?.type === "fixed") {
        

        


        if (markupLists?.status === 1) {
            agentmarkup = parseFloat(markupLists?.value);
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                adminMarkup = ((rate + extras + toll) / 100) * parseFloat(item?.markUpDetails[0]?.value)
            }
            else {
                adminMarkup = parseFloat(item?.markUpDetails[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            adminMarkup = ((rate + extras + toll) / 100) * parseFloat(item?.defaultMarkup?.value)
        }

        if (bookingData?.bookingType === "return") {
            rate = rate * 2;
            adminMarkup = adminMarkup * 2;
            extras = extras * 2;
            toll = toll * 2;
        }

        tax = ((rate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(item?.tax?.value)

        total = parseFloat(rate + adminMarkup + agentmarkup + tax + extras + toll);
    }
    else {


        



        if (user?.role === "agent" && markupLists?.status === 1) {
            agentmarkup = ((rate + extras + toll) / 100) * parseFloat(markupLists?.value)
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                adminMarkup = ((rate + extras + toll) / 100) * parseFloat(item?.markUpDetails[0]?.value)
            }
            else {
                adminMarkup = parseFloat(item?.markUpDetails?.[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            adminMarkup = ((rate + extras + toll) / 100) * parseFloat(item?.defaultMarkup?.value)
        }

        if (bookingData?.bookingType === "return") {
            rate = rate * 2;
            adminMarkup = adminMarkup * 2;
            extras = extras * 2;
            toll = toll * 2;
        }

        tax = ((rate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(item?.tax?.value)

        total = parseFloat(rate + adminMarkup + agentmarkup + tax + extras + toll).toFixed(2);
    }

    const selectCurrentVehicle = (data) => {

        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })

        let agentmarkup = 0;
        let adminMarkup = 0
        let tax = 0;
        let total = 0;
        let additionalStop = 0;
        let baserate = 0;
        let extras = 0;
        let extraCharges = null;
        let toll = data?.tollrate ? parseFloat(data?.tollrate) : 0;

        extraCharges = data?.extraCharges?.map(extra => {
            if (extra?.rate_type === "percentage") {
                extras += bookingData?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
                return {
                    id: extra?.addonitem?.id,
                    name: extra?.addonitem?.name,
                    price: bookingData?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
                }
            }
            else {
                extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
                return {
                    id: extra?.addonitem?.id,
                    name: extra?.addonitem?.name,
                    price: bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
                }
            }
        })

        additionalStop = bookingData?.stops ? additionalStops ? bookingData?.stops?.length * additionalStops : bookingData?.stops?.length * 20 : 0;
        baserate = bookingData?.type === "hour" ? (parseFloat(data?.rate) * parseFloat(bookingData?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop

        if (user?.role === "agent" && markupLists?.type === "fixed") {
            

            

            if (markupLists?.status === 1) {
                agentmarkup = parseFloat(markupLists?.value);
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            tax = ((baserate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(data?.tax?.value)

            total = parseFloat(baserate + adminMarkup + agentmarkup + extras + tax + toll);
        }
        else {

            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = ((baserate + extras + toll) / 100) * parseFloat(markupLists?.value)
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            tax = ((baserate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(data?.tax?.value)

            total = parseFloat(baserate + adminMarkup + agentmarkup + extras + tax + toll);
        }

        let datas = {
            ...data,
            price: baserate,
            adminMarkup,
            agentmarkup,
            extraRate: extras,
            total: total,
            taxValue: tax,
            addonItem: data?.extraCharges,
            toll: toll,
            extraCharges

        }

        dispatch({
            type: SELECTED_VEHICLE,
            payload: datas
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: datas,
                id: 'current'
            }
        })

        navigate("/passengerdetails")
    }



    return (
        <Box display={"flex"} flexDirection="row" mt={1}>
            <div style={{ flex: 0.92 }}>
                <Typography className='nameText'>{item?.vehicledetails?.vehicle_type?.vehicle_type}</Typography>
                <Typography className='classText'>{capitalize(item?.vehicledetails?.veh_details?.brand)}</Typography>
                {
                    item?.vehicledetails?.description ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 0, alignItems: 'center', marginBottom: 5, marginTop: 5 }}>
                        <img src={CarImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{item?.vehicledetails?.description}</Typography>
                    </div> : <div style={{ width: 30, height: 37 }}>
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{item?.vehicledetails?.description}</Typography>
                    </div>
                }
                <Box display={"flex"} flexDirection="row" justifyContent="space-between" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 0, flex: 0.5, alignItems: 'center' }}>
                        <img src={AgegroupImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max: {item?.vehicledetails?.vehicle_type?.no_of_passenger}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flex: 0.5, alignItems: 'center' }}>
                        <img src={BagImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max: {item?.vehicledetails?.vehicle_type?.no_hand_carry}</Typography>
                    </div>
                </Box>
                <Box display={"flex"} flexDirection="row" justifyContent="space-between" alignItems={"center"} flex={1} mt={3}>
                    <Typography className='pricetext' flex={0.8}>S${parseFloat(total).toFixed(2)}</Typography>
                    <div style={{ flex: 0.5 }}>
                        <Button

                            variant="contained"
                            style={{
                                borderRadius: 1,
                                backgroundColor: '#333333',
                                height: 30,
                                borderRadius: 4,
                                padding: 5
                            }}
                            onClick={() => selectCurrentVehicle(item)}
                            className='buttonText'
                        >
                            Select
                        </Button>
                    </div>

                </Box>
                <Grid xs={12} md={4}>
                    {item?.vehicledetails?.vehicle_images?.length > 0 ? <img src={`${item.imageBasePath}${item?.vehicledetails?.vehicle_images[0].path}`} style={{ width: 300, height: '100%' }} resizeMode={'contain'} /> : <img src={Vehicle1Img} style={{ width: 300, height: '100%' }} resizeMode={'contain'} />}
                </Grid>
            </div>
            <Divider flexItem orientation='vertical' />
        </Box>
    )
}

export default Vehicle