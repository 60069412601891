import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout'
import './style.scss'
import TransactionsList from './TransactionsList'
import { Button, Grid, Typography, Snackbar, Alert, Stack, Box } from '@mui/material'
import { toast } from 'react-toastify'
import AddTopUp from './AddTopUp'
import { getBanks } from '../../Redux/actions/topupActions'
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { getProfile } from '../../Redux/actions/profileActions';
import CustomDate from '../../components/CustomDate'

const Wallet = ({ head }) => {
    const NewTopUp = () => {
        setViewModalShow(true);
    }
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const [viewModalShow, setViewModalShow] = useState(false);
    const { newTopup, error, transactionLists } = useSelector(state => state.topup)
    const { user } = useSelector(state => state.auth)
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (newTopup) {
            setOpen(true)

            setViewModalShow(false);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, newTopup])
    useEffect(() => {
        dispatch(getBanks())
        let id = user?.id
        dispatch(getProfile(id))
    }, [])

    const { bankLists } = useSelector(state => state.topup)

    return (
        <Layout head={head}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Topup added Successfully
                </Alert>
            </Snackbar>
            {/* <TopBar activeMenu="2"/>  */}

            <h1 class='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#B6A269' }}>Wallet</span> Details</h1>
            <Grid container spacing={2} pt={1.2} pl={2} >
                <Stack flexDirection={"row"} justifyContent="space-between" width={"100%"}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                        {/* <span><ErrorIcon style={{ width: 20, height: 20 }} /></span> */}
                        <span> <Typography fontSize={{ xs: '12px', md: '12px' }} fontFamily='Nunito' >Top up your wallet for easy checkout!</Typography> </span>
                    </div>

                </Stack>
                <Stack flexDirection={'row'} justifyContent="space-between" alignItems={"center"} width={"100%"}>

                    <Box display={"flex"} flexDirection="row" justifyContent={"center"} alignItems="center" pt={0} pb={2}>
                        <Typography fontSize='20px' fontFamily='Nunito' fontWeight="bold">Current Balance: </Typography>
                        <Stack variant="contained" justifyContent={"center"} alignItems="center"
                            style={{ color: '#333333', fontSize: '20px', fontFamily: 'Nunito', fontWeight: 'bold', padding: 5 }} >
                            S${((user?.walletbalance ? parseFloat(user?.walletbalance).toFixed(2) : 0))}
                        </Stack>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
                            <Button variant="contained"
                                style={{ color: 'white', backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }} onClick={NewTopUp}
                                className="tabStyle commonButtonSize" >

                                TOP UP </Button>
                            <Grid display={{ md: 'flex', xs: 'none' }} gap={1} >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography fontFamily={'Nunito'} fontSize={12} >From</Typography>
                                    <CustomDate  changeDate={(value) => setFromDate(value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography  fontFamily={'Nunito'} fontSize={12} >To</Typography>
                                    <CustomDate  changeDate={(value) => setToDate(value)} />
                                </div>
                            </Grid>
                        </div>
                    </Box>
                    
                </Stack>
                <Grid  container  display={{ md: 'none', xs: 'flex' }} py={2}>
                        <Grid item  md={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomDate label={'From'} changeDate={(value) => setFromDate(value)} />
                        </Grid>
                        <Grid item  md={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomDate label={'To'} changeDate={(value) => setToDate(value)} />
                        </Grid>
                    </Grid>
            </Grid>
            <TransactionsList fromDate={fromDate} toDate={toDate} />
            {viewModalShow &&
                <AddTopUp
                    bankLists={bankLists}
                    openModal={viewModalShow}
                    closeModal={() => setViewModalShow(false)}
                />
            }
        </Layout>
    )
}

export default Wallet
