import {
    LOADING,
    LOGIN_SUCCESS,
    RESET_USER,
    LOGIN_FAIL,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    SUB_USERS_SUCCESS,
    SUB_USERS_FAIL,
    NEW_USER_SUCCESS,
    NEW_USER_FAIL,
    GUEST_LOGIN_SUCCESS,
    PARTNER_MARKUPS,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_FAIL
} from "../constants/userConstants";
import axios from '../../CustomAxios'
import { API_KEY } from "../../config/Constants";
import reactotron from "../../ReactronConfig";
import { getMarkup } from "./markupActions";

//get User Token 
export const getUserToken = () => async (dispatch) => {

    // const token = localStorage.getItem("token");

    // if(token){
    //     dispatch(getThirdPartyMarkups())
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}` ;
    //     let user  = await localStorage.getItem("user");
    //     if(user){
    //         let details = JSON.parse(user);
    //         if(details?.id){
    //             dispatch(getUserProfile(details?.id))
    //             dispatch(getMarkup(details?.parentDetails ? details?.parentDetails?.id : details?.id))
    //         }

    //     }
    // }
    // else{
    //     let data = {
    //         key : API_KEY,
    //         password : "123456"
    //     }
    //     await axios.post(`auth/verify`, data)
    //     .then(async response => {
    //         await localStorage.setItem("token", response.data.access_token);
    //         axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}` ;
    //         let user  = await localStorage.getItem("user");
    //         if(user){
    //             let details = JSON.parse(user);
    //             dispatch(getUserProfile(details?.id))
    //             dispatch(getMarkup(details?.parentDetails ? details?.parentDetails?.id : details?.id))
    //             dispatch(getThirdPartyMarkups())
    //         }

    //     })
    //     .catch(async error => {

    //     });
    // }
    let data = {
        key: API_KEY,
        password: "123456"
    }
    await axios.post(`auth/verify`, data)
        .then(async response => {
            await localStorage.setItem("token", response.data.access_token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
            let user = await localStorage.getItem("user");
            dispatch(getThirdPartyMarkups())
            if (user) {
                let details = JSON.parse(user);
                console.log({details}) 
                //   if(details?.id){
                    dispatch(getUserProfile(details?.id))
                //   }
                dispatch(getMarkup(details?.parentDetails ? details?.parentDetails?.id : details?.id))

            }

        })
        .catch(async error => {

        });


}

//Login User
export const loginUsers = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`auth/login`, data)
        .then(async response => {
            dispatch({
                type: LOADING,
                payload: false
            })

            let data = response.data.data

            if (data?.customer_type === 3) {
                data.role = 'customer'
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: data
                })
                await localStorage.setItem("user", JSON.stringify(data));
            }
            else if (data?.is_corporate === 2) {

                if (data?.customer_type === 1) {
                    data.role = 'corporate';
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    await localStorage.setItem("user", JSON.stringify(data));
                }
                else if (data?.customer_type === 2) {
                    data.role = 'agent'
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    dispatch(getMarkup(data?.parentDetails ? data?.parentDetails?.id : data?.id))
                    await localStorage.setItem("user", JSON.stringify(data));
                }
                else {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: 'Invalid User'
                    })
                }
            }
            else {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: 'Invalid User'
                })
            }


        })
        .catch(async error => {
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: LOGIN_FAIL,
                payload: error
            })

        });
}


//Customer and B2B Registration
export const userRegister = (formData) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`auth/register`, formData)
        .then(async response => {
  
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: response.data
            })

        })
        .catch(async error => {
          

            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: USER_REGISTER_FAIL,
                payload: error
            })

        });
}

//Google Signin
export const googleSignIn = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`auth/google-auth`, data)
        .then(async response => {
         
            dispatch({
                type: LOADING,
                payload: false
            })

            let data = response.data.data

            if (data?.customer_type === 3) {
                data.role = 'customer'
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: data
                })
                await localStorage.setItem("user", JSON.stringify(data));
            }
            else if (data?.is_corporate === 2) {
                if (data?.customer_type === 1) {
                    data.role = 'corporate';
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    await localStorage.setItem("user", JSON.stringify(data));
                }
                else if (data?.customer_type === 2) {
                    dispatch(getMarkup(data?.parentDetails ? data?.parentDetails?.id : data?.id))
                    data.role = 'agent'
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    await localStorage.setItem("user", JSON.stringify(data));
                }
                else {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: 'Invalid User'
                    })
                }
            }
            else {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: 'Invalid User'
                })
            }

        })
        .catch(async error => {
         

            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: LOGIN_FAIL,
                payload: error
            })

        });
}


export const getUserProfile = (userId) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`auth/profile/${userId}`)
        .then(async response => {

            dispatch({
                type: LOADING,
                payload: false
            })

            let data = response.data.data

            if (data?.customer_type === 3) {
                data.role = 'customer'
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: data
                })
                await localStorage.setItem("user", JSON.stringify(data));
            }
            else if (data?.is_corporate === 2) {
                if (data?.customer_type === 1) {
                    data.role = 'corporate';
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    await localStorage.setItem("user", JSON.stringify(data));
                }
                else if (data?.customer_type === 2) {
                    dispatch(getMarkup(data?.parentDetails ? data?.parentDetails?.id : data?.id))
                    data.role = 'agent'
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    await localStorage.setItem("user", JSON.stringify(data));
                }
                else {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: 'Invalid User'
                    })
                }
            }
            else {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: 'Invalid User'
                })
            }


        })
        .catch(async error => {
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: LOGIN_FAIL,
                payload: error
            })

        });
}


//Get Users List
export const getUserList = (formData) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`userlist`, formData)
        .then(async response => {
          
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: SUB_USERS_SUCCESS,
                payload: response.data.data
            })

        })
        .catch(async error => {
           
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: SUB_USERS_FAIL,
                payload: error
            })

        });
}

//Add New User
export const addNewUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`createuser`, data)
        .then(async response => {
          
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: NEW_USER_SUCCESS,
                payload: response.data
            })

        })
        .catch(async error => {
           
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: NEW_USER_FAIL,
                payload: error
            })

        });
}

//Booking Login
export const BookingLogin = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`auth/login`, data)
        .then(async response => {

            dispatch({
                type: LOADING,
                payload: false
            })

            let data = response.data.data

            if (data?.customer_type === 3) {
                data.role = 'customer'
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: data
                })
                await localStorage.setItem("user", JSON.stringify(data));
            }
            else {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: { message: 'Invalid User' }
                })
            }


        })
        .catch(async error => {
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: LOGIN_FAIL,
                payload: error
            })

        });
}

//Get Third Party Markups
export const getThirdPartyMarkups = () => async (dispatch) => {
    await axios.get(`getmarkups`)
        .then(async response => {
            let data = response.data.data.markups;
            let additionalStops = parseFloat(response.data.data.additionalStop);
            let myTransfer = null;
            let gett = null;
            let gnet = null;
            data.map(partner => {
                if (partner.supplier_name === "My Transfer") {
                    myTransfer = partner;
                }
                else if (partner.supplier_name === "Gett") {
                    gett = partner;
                }
                else if (partner.supplier_name === "Gnet") {
                    gnet = partner;
                }
            })

            dispatch({
                type: PARTNER_MARKUPS,
                payload: {
                    myTransfer,
                    gett,
                    gnet,
                    additionalStops
                }
            })

        })
        .catch(async error => {

        });
}

//Update user permissions
export const addUserRole = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`addroles`, data)
        .then(async response => {
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: ROLE_UPDATE_SUCCESS
            })

        })
        .catch(async error => {
           
            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch({
                type: ROLE_UPDATE_FAIL
            })

        });
}
