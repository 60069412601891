import React, { Fragment, useState } from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles, styled } from '@mui/styles';
import './style.scss'
import { setHours, setMinutes } from 'date-fns';



const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        paddingLeft: 3,
        paddingTop:4,
        height:30   
    },
}));


const TimePicker = ({ fieldName, fieldType, fieldLabel, minDate, width, fontSize, control, minTime, error, children, marginRight, maxDate, mode }) => {



    const [value, setValue] = React.useState(null);



    const classes = useStyles();

    const changeDateValue = (value) => {
        setValue(value)
    }




    return (
        <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }} >
            <Typography className='formText' sx={{ fontSize: '12px' }} >{fieldLabel}</Typography>

            <Controller
                name={fieldName}
                control={control ? control : null}
                render={({ field: { value, onChange } }) => <DatePicker
                    selected={value}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Time"  
                    dateFormat="h:mm aa"
                    //popperPlacement={popperPlacement}
                    onChange={(date) => {
                        onChange(date)
                        changeDateValue(date)
                    }}
                    customInput={<TextField
                      className='texttimePicker'
                        fullWidth
                        size='small'
                        error={error?.message ? true : false}
                        variant='standard'
                        style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius:2, width: '100%', justifyContent: 'center',alignItems:'center', height: 30}}
                        sx={{
                            "& .MuiFilledInput-underline:before": {
                                borderBottom: "none"
                            },
                            "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                                borderBottom: "none"
                            }, borderRadius: '0px'
                        }}
                        placeholder={"fieldLabel"}
                        InputProps={{
                            disableUnderline: true,
                            placeholder: fieldLabel,
                            classes: {
                                input: classes.resize
                            }
                        }}
                    >{children}
                    </TextField>}
                />
                } />
            {error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
        </FormGroup>
    )

}

export default TimePicker