

import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Typography, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './style.scss'
import { useDispatch, useSelector } from 'react-redux';

import { withStyles } from '@mui/styles';
import VehicleCard from './VehicleCard';
import { BOOKING_INPUT, COUPON_VERIFY_FAIL } from '../Redux/constants/bookingConstants';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import reactotron from '../ReactronConfig';


const AddedVehicle = ({ page }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(0)

    const StyledAccordionSummary = withStyles({
        root: {
            minHeight: 30,
            maxHeight: 30,
            backgroundColor: '#a5a5a5',
            '&.Mui-expanded': {
                minHeight: 30,
                maxHeight: 30,
                backgroundColor: '#fff',
            }
        },
        expandIcon: {
            order: -1
        }   
    })(AccordionSummary);

    const { user } = useSelector(state => state.auth)

    const { selectedBooking: booking, currentVehicle: data, bookingType } = useSelector(state => state.booking)
    const { orderData, selectedService } = useSelector(state => state.booking)

    //reactotron.log({booking})

    const deleteBooking = (index) => {
        let orderDatas = orderData.filter((or, i) => i !== index)
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'orderData',
                value: orderDatas
            }
        })



        if (orderDatas?.length === 0 && isEmpty(booking)) {
            if (user) {
                navigate('/newbooking')
            }
            else {
                navigate('/')
            }

        }
    }

    return (
        <Box border={"0px solid rgba(112, 112, 112, 0.2)"} borderRadius={"0px !important"}>
            <div style={{ height: 30, backgroundColor: '#B6A269', paddingLeft: 10, paddingTop: 10, borderTopRightRadius: 20, borderTopLeftRadius: 20, color: '#fff' }} >ITINERARY</div>
            <div style={{ maxHeight: 500, overflowY: 'auto' }}>
                {orderData && orderData?.map((order, index) => (
                    <VehicleCard
                        mode="order"
                        deleteBooking={() => {
                            deleteBooking(index)
                            dispatch({
                                type: COUPON_VERIFY_FAIL
                            })
                        }}
                        booking={order?.booking_data}
                        edit={'edit'} del={'del'}
                        data={order?.selectedVehicle}
                        bookingType={order?.bookingType}
                        trip={index + 1}
                    />
                ))}
                {booking && <VehicleCard mode="current" booking={booking} data={data} bookingType={bookingType} trip={orderData && orderData.length > 0 ? orderData?.length + 1 : 1} services={selectedService} />}

            </div>
        </Box>
    )
}

export default AddedVehicle