import React, { useState, useEffect } from 'react'
import { Grid, Modal, Typography, IconButton, Container, Box, FormControl, Stack } from '@mui/material'
import InputField from '../../components/InputField'
import ModalHeader from '../../components/ModalHeader';
import CloseIcon from '@mui/icons-material/Close';
import Loc2Img from '../../assets/images/IconsNew/Loc2Img.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import LittleImg from '../../assets/images/IconsNew/little-kid.png'
import Loc1Img from '../../assets/images/IconsNew/Loc1Img.png'
import Divider from '@mui/material/Divider'
import { useLocation } from 'react-router-dom';
import AdultImg from '../../assets/images/IconsNew/avatar.png'
import BabyImg from '../../assets/images/IconsNew/baby-boy.png'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import reactotron from '../../ReactronConfig';
import MainStop from '../Summary/MainStop';
import SubStop from '../Summary/SubStop';

const ViewTrip = ({ openModal, closeModal, trips }) => {
    const [booking, setBooking] = useState(null);



    console.log({ trips })

    const { user } = useSelector(state => state.auth)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F6F6F6',
        borderRadius: '15px',
        border: '0px solid #fff',
        height: 'auto',
        width: '55%',
    };

    useEffect(() => {
        setBooking(JSON.parse(trips?.row?.booking_data))
    }, [])




    let addOns = trips.row?.addons ? JSON.parse(trips?.row?.addons) : [];
    let addonitems = trips.row?.addonitems ? JSON.parse(trips?.row?.addonitems) : [];

   




    return (
        
        <Modal open={openModal} onClose={closeModal}   >

            <Box sx={style} className='modalScroll' >
                <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} style={{ display: 'flex', height: '35px', flexDirection: 'row', backgroundColor: '#333333', alignItems: 'center', borderRadius: 15 }} pl={2}>
                    {/* <Typography className='modalHeadStyle' >Trip ID : #{trips.id}</Typography> */}
                    <Typography className='vehiclename'>{trips?.row?.vehicletype ?trips?.row?.vehicletype.vehicle_type : ''}</Typography>
                    <Typography className='modalDate'  > {moment(trips?.row?.pickupdate, "YYYY-MM-DD").format("DD-MM-YYYY")}  {trips?.row.pickuptime}
                        <IconButton onClick={closeModal} style={{ alignSelf: 'flex-end', flex: 0.05 }}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton></Typography>
                </Stack>



                <Grid container spacing={0} style={{ paddingTop: '1%', paddingLeft: '4%', paddingRight: 0 }}>
                    <Grid xs={12} md={9} sx={{ bgcolor: '#fff', flexGrow: 1, paddingBottom: 1 }}>
                        {/* {(user?.role === "agent" || user?.role === "corporate") && <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <Typography className='companytext' >Company Name :</Typography>
                            </span>
                            <span><Typography className='newText' >{user?.companyinfo?.company_name}</Typography> </span>
                        </div>} */}
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', paddingLeft: '1%' }}>
                            <span>
                                <Typography className='modalHeadText'> Passenger Details</Typography>
                            </span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography className='namesText' >Name </Typography>
                                    <Typography className='storeText' > : {trips?.row?.name}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography className='namesText' > Email ID </Typography>
                                    <Typography className='storeText' > : {trips?.row?.email}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography className='namesText' >Mobile </Typography>
                                    <Typography className='storeText' > : {trips?.row?.mobile}</Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={3} sx={{ bgcolor: '#fff', height: 'auto', flexGrow: 1 }} padding={1}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 2 }}>
                            <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <img src={AdultImg} style={{ width: 25, height: 25 }} />
                                <Typography className='capacityText'>Adults</Typography>
                                <Typography className='capacityText'>{trips?.row?.adults}</Typography>
                            </Stack>
                            <Stack style={{ marginLeft: '2%', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={LittleImg} style={{ width: 25, height: 25 }} />
                                <Typography className='capacityText'>Baby</Typography>
                                <Typography className='capacityText'>{trips?.row?.baby_seats}</Typography>
                            </Stack>
                            <Stack style={{ marginLeft: '2%', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={LittleImg} style={{ width: 25, height: 25 }} />
                                <Typography className='capacityText'>Child </Typography>
                                <Typography className='capacityText'>{trips?.row?.childrens}</Typography>
                            </Stack>
                            <Stack style={{ marginLeft: '2%', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={LittleImg} style={{ width: 25, height: 25 }} />
                                <Typography className='capacityText'>Booster </Typography>
                                <Typography className='capacityText'>{trips?.row?.booster_seats}</Typography>
                            </Stack>
                        </div>


                    </Grid>
                    <Grid xs={12} md={9} sx={{ bgcolor: '#fff', height: 'auto', flexGrow: 1 }}>
                        <MainStop
                            address={booking?.location_from?.address}
                            count={1}
                            color="#FF0000"
                        />
                        {booking?.location_to !== null &&
                            <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.1, marginTop: -2, marginBottom: -1, paddingBottom: 2, minHeight: 10, borderLeft: '2px dashed', borderRight: 'none', borderRightColor: "#B6A269", }} >
                                {booking?.stops?.map((stop, index) => (
                                    <SubStop
                                        address={stop?.stop_name?.address}
                                        count={index + 2}
                                        color="blue"
                                        index={index}
                                    />
                                ))}

                            </Divider>}
                        {booking?.location_to !== null &&
                            <MainStop
                                address={booking?.location_to?.address}
                                count={booking?.stops ? booking?.stops?.length + 2 : 2}
                                color="#0066FF"
                            />}
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <img src={Loc1Img} style={{ width: 28, height: 30,paddingRight:10  }} />
                            </span>
                            <span>
                            <Typography className='loctext' >{trips.row.location_from}</Typography>
                            </span>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ width:'0px',height: '10%',marginLeft:2.5,marginTop:-3,marginBottom:-1 ,paddingBottom:2,
                        borderRight:'1.5px dashed' ,borderLeft:'none',borderRightColor: "#B6A269"}} > </Divider>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <img src={Loc2Img} style={{ width: 28, height: 30,paddingRight:10 }} />
                            </span>
                            <span>
                            <Typography className='loctext'>{trips.row.location_to}</Typography>
                            </span>
                        </div> */}

                    </Grid>

                    {/* <Grid xs={12} md={3} sx={{ bgcolor: '#F6F6F6', height: 'auto',flexGrow:1}}>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center'}}>
                            <span>
                            <img src={trips.row.vehicle_image} width="auto" height="120" />
                            </span>
                            
                        </div>
                        
                    </Grid> */}
                    <Grid xs={12} md={12} sx={{ bgcolor: '#fff', height: 'auto', flexGrow: 1, paddingBottom: '1%' }}>
                        <Divider color='#B6A269' flexItem sx={{ borderWidth: 1 }} />
                    </Grid>
                    {/* <Grid xs={12} md={12} sx={{ bgcolor: '#F6F6F6', height: 'auto', flexGrow:1}}>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                        <span>
                            <Typography className='companytext' >Transaction ID :</Typography>
                            </span>
                            <span><Typography className='newText' ></Typography> </span> 
                           
                        </div>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                        <span>
                            <Typography className='companytext' >Payment Status :</Typography>
                            </span>
                            <span><Typography className='completeText' >{trips?.row?.payment_status}</Typography> </span> 
                           
                        </div>
                    </Grid> */}

                    <Grid xs={12} md={7} sx={{ bgcolor: '#fff', height: 'auto', flex: 0.3, paddingLeft: 1 }}>
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                            <span>
                            <Typography className='modalHeadText'> Payment Details</Typography>
                            </span>
                    </div> */}
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography className='priceText' >All prices are in SGD</Typography>
                    </div> */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', paddingTop: '1%' }}>
                            <span>
                                <Typography className='companytext' >Transaction ID :</Typography>
                            </span>
                            <span><Typography className='newText' style={{ paddingLeft: 4 }}> {`${trips?.row?.paymentdetails?.transactionID}`}</Typography> </span>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', paddingTop: '1%', gap: 5 }}>
                            <div style={{ display: 'flex' }}>
                                <span>
                                    <Typography className='companytext' >Payment Mode : </Typography>
                                </span>
                                <span><Typography className='completeText' style={{ paddingLeft: 4 }}>{trips?.row?.payment_type}</Typography> </span>

                            </div>
                            <div style={{ display: 'flex' }}>
                                <span>
                                    <Typography className='companytext' >Payment Status : </Typography>
                                </span>
                                <span><Typography className='completeText' style={{ paddingLeft: 4 }}>{trips?.row?.paymentdetails?.status}</Typography> </span>

                            </div>


                        </div>
                    </Grid>
                    <Grid xs={12} md={5} sx={{ bgcolor: '#fff', height: 'auto', flex: 0.3 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                            <Typography className='modalHeadText'> Total Fare</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: '30%',
                                flex: 0.5,
                                fontSize: 12
                            }}>Base Price</Typography>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                textAlign: 'right',
                                paddingRight: 10,
                                flex: 0.5
                            }} >S${parseFloat(trips?.row?.pricebreakups?.base_rate + trips?.row?.pricebreakups?.markup).toFixed(2)}</Typography>
                        </div>
                        {user && user.role === "agent" && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: '30%',
                                fontSize: 12,
                                flex: 0.5
                            }}>Markup Price</Typography>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                textAlign: 'right',
                                paddingRight: 10,
                                flex: 0.5
                            }} >S${parseFloat(trips?.row?.pricebreakups?.agentMarkup).toFixed(2)}</Typography>
                        </div>}
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: '30%', 
                            flex: 0.5,
                            fontSize: 12
                        }}>Extra Charges</Typography>
                        <Typography style={{ 
                            fontFamily: 'Nunito',
                            fontSize: 12,
                            paddingRight: 10, 
                            flex: 0.5  }}  >S${parseFloat(trips?.row?.pricebreakups?.extraCharges).toFixed(2)}</Typography>
                    </div> */}
                        {addOns?.map((add, index) => (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }} key={index}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    paddingRight: '30%',
                                    flex: 0.5,
                                    fontSize: 12
                                }}>{add?.name}</Typography>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    textAlign: 'right',
                                    paddingRight: 10,
                                    flex: 0.5
                                }}  >S${parseFloat(add?.price).toFixed(2)}</Typography>
                            </div>
                        ))}
                        {addonitems?.map((add, index) => (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }} key={index}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    paddingRight: '30%',
                                    flex: 0.5,
                                    fontSize: 12
                                }}>{add?.name}</Typography>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    paddingRight: 10,
                                    textAlign: 'right',
                                    flex: 0.5
                                }}  >S${parseFloat(add?.price).toFixed(2)}</Typography>
                            </div>
                        ))}
                        {parseFloat(trips?.row?.pricebreakups?.toll) != 0 &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    paddingRight: '30%',
                                    flex: 0.5,
                                    fontSize: 12,
                                }}>Toll</Typography>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    paddingRight: 10,
                                    textAlign: 'right',
                                    flex: 0.5
                                }}  >S${parseFloat(trips?.row?.pricebreakups?.toll).toFixed(2)}</Typography>
                            </div>}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: '30%',
                                fontSize: 12,
                                flex: 0.5
                            }}>Tax</Typography>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                paddingRight: 10,
                                textAlign: 'right',
                                flex: 0.5
                            }}  >S${parseFloat(trips?.row?.pricebreakups?.tax).toFixed(2)}</Typography>
                        </div>


                        <Divider orientation="horizontal" sx={{ bgcolor: "#F6F6F6", height: '0px', width: '100%', marginTop: 1 }} flexItem />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%', marginBottom: 5, marginTop: 8 }}>
                            <Typography style={{ color: '#333333', fontWeight: 'bold', paddingRight: '28%', flex: 0.5, fontSize: 14 }} >Total</Typography>
                            <Typography style={{ color: '#333333', fontWeight: 'bold', flex: 0.5, fontSize: 14, textAlign: 'right' }} >S${parseFloat(trips?.row?.pricebreakups?.total_price).toFixed(2)}</Typography>
                        </div>
                    </Grid>
                </Grid>

            </Box>

        </Modal>
    )
}

export default ViewTrip
