import React, { Fragment, useState } from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles, styled } from '@mui/styles';
import './style.scss'
import { setHours, setMinutes } from 'date-fns';



const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        paddingLeft: 2
    },
}));

const SearchBox = styled(TextField)(() => ({
    '& input': {
        paddingLeft: '4px',
        height: '15px',
        paddingLeft: 2
    },
    '& fieldset': {
        borderRadius: '4px',
    },
}));

const DateInput = ({ fieldName, fieldType, fieldLabel, minDate, width, fontSize, control, minTime, error, children, marginRight, maxDate, mode ,todaycheck}) => {

   

    const [value, setValue] = React.useState(null);
    const [check,setcheck]=useState(todaycheck);



      

    const classes = useStyles();

    const changeDateValue = async(value) => {
        let isToday = moment(value).isSame(moment(), 'day');
        setValue(value)
        setcheck(isToday)
        // changeDate(moment(value).format("DD-MM-YYYY hh:mm A"))
    }



    const calculateMinTime = date => {
        let isToday = moment(date).isSame(moment(),'day');
        if (isToday) {
            let nowAddOneHour = moment(new Date()).add({ hours: 0 }).toDate();
            return nowAddOneHour;
        }
        return moment().startOf('day').toDate(); // set to 12:00 am today
    }

    let miniTime = calculateMinTime(new Date())

    return (
        <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }} >
            <Typography className='formText' sx={{ fontSize: '12px' }} >{fieldLabel}</Typography>

            <Controller
                name={fieldName}

                control={control ? control : null}
                render={({ field: { value, onChange } }) => <DatePicker
                    showTimeSelect
                    autoComplete='off'
                    minDate={minDate ? minDate : null}
                    maxDate={maxDate ? maxDate : null}
                    minTime={mode && check ? setHours(setMinutes(new Date(),15),moment().hours()): moment()}
                    maxTime={mode ? moment().endOf('day').toDate() : moment()}
                    timeIntervals={15}
                    selected={value}
                    id='date-time-picker'
                    dateFormat='dd-MM-yyyy h:mm aa'
                    //popperPlacement={popperPlacement}
                    onChange={(date) => {
                        onChange(date)
                        changeDateValue(date)
                    }}
                    customInput={<TextField
                        fullWidth
                        size='small'
                        error={error?.message ? true : false}
                        variant='standard'
                        style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: 4, width: '99%', justifyContent: 'center', height: 27, paddingTop: 3 }}
                        sx={{
                            "& .MuiFilledInput-underline:before": {
                                borderBottom: "none"
                            },
                            "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                                borderBottom: "none"
                            }, borderRadius: '0px'
                        }}
                        placeholder={"fieldLabel"}
                        InputProps={{
                            disableUnderline: true,
                            placeholder: fieldLabel,
                            classes: {
                                input: classes.resize
                            }
                        }}
                    >{children}
                    </TextField>}
                />
                } />
            {error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}    
        </FormGroup>
    )


    // return (
    //     <LocalizationProvider dateAdapter={AdapterMoment}>
    //         <FormGroup sx={{ m: 1, width: width, marginTop: 2, marginRight: marginRight }}  >
    //             <Typography className='formText' sx={{ fontSize: fontSize ? { fontSize } : '14px' }} >{fieldLabel}</Typography>

    //             <Controller
    //                 name={fieldName}
    //                 fullWidth
    //                 control={control ? control : null}
    //                 render={({ field }) => <DateTimePicker
    //                     inputFormat='DD-MM-YYYY hh:mm A'
    //                     value={value}
    //                     minDate={minDate ? minDate : null}
    //                     maxDate={maxDate ? maxDate : null}
    //                     InputProps={{
    //                         disableUnderline: true,
    //                     }}
    //                     onChange={(newValue) => {
    //                   
    //                         changeDateValue(newValue);
    //                     }}
    //                     {...field}
    //                     renderInput={(params) => <TextField

    //                         {...params}
    //                         error={error?.message ? true : false}


    //                         variant='standard'
    //                         style={{ backgroundColor: '#F6F6F6', padding: 5 }}

    //                         sx={{
    //                             "& .MuiFilledInput-underline:before": {
    //                                 borderBottom: "none"
    //                             },
    //                             "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
    //                                 borderBottom: "none"
    //                             }, borderRadius: '2px'
    //                         }}
    //                         placeholder={fieldLabel}
    //                     >{children}
    //                     </TextField>}
    //                 />
    //                 } />
    //             {error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}

    //         </FormGroup>
    //     </LocalizationProvider>
    // )
}

export default DateInput