import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material';
import { capitalize, isObject } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import Blue from '../assets/images/blue.png'
import Red from '../assets/images/red.png'
import distancePin from '../assets/images/distancePin.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import * as geolib from 'geolib';
import { toast } from 'react-toastify';
import customAxios from '../CustomAxios';
import { CameraFeed } from './CameraFeed';
import { Close, CloseOutlined, CloudDownload } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { IMAGE_URL } from '../config/Constants';



const DriverTrip = () => {

    const [open, setOpen] = useState(false)
    const [noShow, setNoShow] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [aditionalStops, setAditionalStops] = useState(null)
    const [booking_data, SetBookingData] = useState(null)
    const [files, setFiles] = useState([])

    const [searchParams, setSearchParams] = useSearchParams();

    // let id = searchParams.get("id")

    // console.log(id)

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    // let trips = {
    //     "id": 97,
    //     "name": "Anu",
    //     "email": "a@gmail.com",
    //     "phone": "9496592933",
    //     "mobile": "9496592933",
    //     "namesign": null,
    //     "type": "airport",
    //     "nationality": null,
    //     "passport": null,
    //     "adults": 2,
    //     "childrens": null,
    //     "minors": 0,
    //     "suitcase": 0,
    //     "carryon": 0,
    //     "specialinstructions": "test",
    //     "pickupdate": "2023-03-21",
    //     "enddate": null,
    //     "pickuptime": "05:00 AM",
    //     "date_time": null,
    //     "pickup_loc_type": "Airport",
    //     "location_from": "Singapore Changi Airport",
    //     "location_to": "Yishun",
    //     "flightno": null,
    //     "flight_type": null,
    //     "cruise_no": null,
    //     "disembarkment": null,
    //     "flightdate": null,
    //     "flighttime": null,
    //     "supplier_id": 2,
    //     "vehicle_type": 18,
    //     "vehicle_id": 152,
    //     "driver_id": 78,
    //     "tier1_sent": 0,
    //     "tier2_sent": 1,
    //     "customer_id": null,
    //     "stops": null,
    //     "status": 2,
    //     "driver_status": 1,
    //     "payment_id": null,
    //     "payment_type": "UPI",
    //     "payment_status": "",
    //     "totalprice": "65.97",
    //     "booking_id": 117,
    //     "booking_data": "{\"type\": \"airport\", \"passenger\": 2, \"waypoints\": [], \"pickupdate\": \"Tue Dec 27 5:00:00\", \"returndate\": null, \"bookingType\": \"\", \"location_to\": {\"address\": \"Yishun\", \"latitude\": 1.430368, \"longitude\": 103.8353628}, \"location_from\": {\"address\": \"Singapore Changi Airport\", \"latitude\": 1.3644202, \"longitude\": 103.9915308}}",
    //     "arrival": "Arrival",
    //     "booking_type": null,
    //     "vehicle_image": "https://pal.diginestsolutions.in/assets/img/vehicles/ilZsP_Mercedes-S-Class-for-Business-Meeting.jpg (2).jpg",
    //     "buffer_time": null,
    //     "baby_seats": 0,
    //     "booster_seats": 0,
    //     "addons": "[]",
    //     "addonitems": null,
    //     "customer_reference": null,
    //     "contact_person": null,
    //     "contact_person_phone": null,
    //     "price_plan": 341,
    //     "itenary": null,
    //     "baggage_protection": null,
    //     "remark": null,
    //     "trip_reminder": 1,
    //     "pricelist": null,
    //     "created_by": 1,
    //     "notification": null,
    //     "created_at": "2023-03-17 17:44:03",
    //     "updated_at": "2023-03-21 07:58:44",
    //     "customer_details": null,
    //     "vehicletype": {
    //         "id": 18,
    //         "vehicle_type": "Business Sedan"
    //     },
    //     "noshowimages": []
    // }

    const [trip, setTrip] = useState(null)
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        getOtherDriverTrips()
    }, [])


    const getOtherDriverTrips = async() => {
        setLoading(true)
        await customAxios.get(`otherdriver/${searchParams.get("id")}`)
        .then(async response => {
            if(isObject(response?.data?.data) ){
                setTrip(response?.data?.data)
                setAditionalStops(JSON.parse(response?.data?.data?.stops))
                SetBookingData(JSON.parse(response?.data?.data?.booking_data))
            }
            else{
                setTrip(null)
            }
            setLoading(false)
        })
        .catch(async error => {
            setTrip(null)
            setLoading(false)
        })
    }
    

    const updateTripStatus = async(data) => {
        setLoading(true)
        await customAxios.get(`updatetripstatus`, {
            params: data
        })  
        .then(async response => {
            setLoading(false)
            setTrip(response?.data?.data)
        })
        .catch(async error => {
           setLoading(false)
        });
    }

    const makeActiveTrip = () => {
        let data ={
            tripid: trip?.id,
            status: 2,
            time: moment().format("hh:mm A"),
            other_driver: searchParams.get("id")
        }
        setOpen(false)
        updateTripStatus(data)
    }

    const ChangeStatus = (status) => {
        let data ={
            tripid: trip?.id,
            status: status,
            time: moment().format("hh:mm A"),
            other_driver: searchParams.get("id")
        }
        updateTripStatus(data)
    }

    


    const getLocation = () => {
        setLoading(true)
        navigator.geolocation.getCurrentPosition((position) =>  {
            let currentLocation = {
                latitude: position?.coords?.latitude,
                longitude: position?.coords?.longitude
            }

            let tripLocation = {
                latitude: booking_data?.location_from?.latitude,
                longitude: booking_data?.location_from?.longitude
            }

            console.log(currentLocation, tripLocation)
            try {
                if(geolib?.isPointWithinRadius(currentLocation, tripLocation, 1000)){
                    setLoading(false)
                    ChangeStatus(3);
                }
                else{
                    setLoading(false)
                    toast.warning(`Sorry. Try again when you are within 1km radius`)
                }
            } catch (error) {
                console.log({error})
                setLoading(false)
            }
            
        });
    }

    const pob = () => {
        setNoShow(false)
        ChangeStatus(4)
    }

    const dropoff = () => {
        ChangeStatus(5)
    }

    const completed = () => {
        ChangeStatus(6)
    }

    const enableNoShow = () => {
        setNoShow(true)
        setShowMore(false)
    }


    const renderTripButton = (status) => {
        switch(status){
            case 1:
                return(
                    <Button fullWidth={true} style={{ backgroundColor: '#B6A269', color: '#fff' }} onClick={handleClickOpen}>
                        START TRIP
                    </Button>
                )

            case 2:
                return(
                    <Button fullWidth={true} style={{ backgroundColor:' #B6A269', color: '#fff' }} onClick={getLocation}>
                        ON LOCATION
                    </Button>
                )
            case 3:
                return(
                    <Box display={"flex"} flexDirection="row" width={"100%"}>
                        <Button fullWidth={true} style={{ backgroundColor:' #B6A269', color: '#fff', marginRight: 5 }} onClick={pob} >
                            POB
                        </Button>
                        <Button fullWidth={true} style={{ backgroundColor:' #B6A269', color: '#fff', marginLeft: 5 }} onClick={enableNoShow}>
                            No Show
                        </Button>
                    </Box>
                )
            case 4:
                return(
                    <Button fullWidth={true} style={{ backgroundColor:' #B6A269', color: '#fff' }} onClick={dropoff}>
                        DROP OFF
                    </Button>
                )
            case 5:
                return(
                    <Button fullWidth={true} style={{ backgroundColor:' #B6A269', color: '#fff' }} onClick={completed}>
                        COMPLETED
                    </Button>
                )

            case 6:
                return(
                    <Button fullWidth={true} disabled style={{ backgroundColor:' #B6A269', color: '#fff' }} onClick={completed}>
                        COMPLETED
                    </Button>
                )

            case 7:
                return(
                    <Button  fullWidth={true} disabled style={{ backgroundColor:' #B6A269', color: '#fff' }} onClick={completed}>
                        No Show
                    </Button>
                )

            case 8:
                return(
                    
                    <Button fullWidth={true} style={{ backgroundColor:' #B6A269', color: '#fff' }} onClick={dropoff}>
                        DROP OFF
                    </Button>
                )

            case 9:
                return(
                    <Button fullWidth={true} disabled style={{ backgroundColor:' #B6A269', color: '#fff' }}>
                        Unconfirmed
                    </Button>
                )

            case 10:
                return(
                    <Button fullWidth={true} disabled style={{ backgroundColor:' #B6A269', color: '#fff' }}>
                        Cancelled
                    </Button>
                )

            case 11:
                return(
                    <Button style={{ backgroundColor: '#B6A269', color: '#fff' }} onClick={handleClickOpen}>
                        START TRIP
                    </Button>
                )
            
    

            default:
                return(
                    <Button disabled fullWidth style={{ backgroundColor:' #B6A269', color: '#fff' }}>
                        Other
                    </Button>
                )
        }
    }

    const showMoreDetails = () => {
        setShowMore(!showMore)
    }

    

    const UploadImages = (file) => {
        if(files.length  === 4){
            toast.warning("Maximum of 4 files are allowed")
        }
        else{
            file.name = 'image.jpeg';
            file.lastModified = new Date();
            setFiles((prev) => [...prev, file])
            console.log({file})
        }
        
	}

    const deleteImage = (index) => {
        let file = files.filter((file, i) => i !=index  )
        setFiles(file)
    }

    const saveNoShow = async() => {
        const formData = new FormData();
		if (files.length > 1) {
			const image_list = [];
			for (var i = 0; i < files.length; i++) {
				const photo = files[i];
				formData.append(`image[${i}]`, photo);
			}
		}
		formData.append("trip_id", trip?.id)
		formData.append("driver_id", searchParams.get("id"))

        setLoading(true)
        await customAxios.post(`updatenoshow`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })  
        .then(async response => {
            setTrip(response?.data?.data)
            setNoShow(false)
            setLoading(false)
        })
        .catch(async error => {
            setLoading(false)
        });
    }

    if(!trip){
        return(
            <Typography>No Trips Found</Typography>
        )
        
    }


    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, alignSelf: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', flexDirection: 'column', border: '1px solid #EEEEEE', minWidth: 380 }}>
                <div style={{ backgroundColor: '#333333', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10, height: 30, position: 'relative' }}>
                    <label  style={{ color: '#fff', fontFamily: 'Nunito', fontWeight: 'bold' }} >{capitalize(trip?.arrival) }</label>
                    {!showMore && <KeyboardArrowRight style={{ color: '#fff', position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} onClick={showMoreDetails} />}
                    {showMore && <KeyboardArrowDownIcon style={{ color: '#fff', position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} onClick={showMoreDetails} />}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', border: '1px solid #EEEEEE' }}>
                    <div style={{ padding: 5 }}>
                        <Typography   color={'#0759B7'} fontWeight={600} fontFamily="Nunito" fontSize={12}  >{trip?.vehicletype?.vehicle_type}</Typography>
                    </div>
                    <div style={{ padding: 5 }}>
                        <Typography  color={'#0759B7'} fontWeight={600} fontFamily="Nunito" fontSize={12}  >{moment(trip?.pickupdate).format("DD-MM-YYYY")}</Typography>
                    </div>
                    <div style={{ padding: 5 }}>
                        <Typography  color={'#0759B7'} fontWeight={600} fontFamily="Nunito" fontSize={12}  >{trip?.pickuptime}</Typography>
                    </div>
                </div>
                {showMore && <div style={{ width: '100%', border: '1px solid #EEEEEE', display: 'flex', justifyContent: 'center', backgroundColor: '#00000029', paddingTop: 3, paddingBottom: 3 }}>
                    <Typography  color={'#0A2638'} fontWeight={600} fontFamily="Nunito" fontSize={12} >Trip ID: #{trip?.id}</Typography>
                </div>}
                {(showMore && trip?.flightno) && <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center' }}>
                    <Typography flex={0.25}  fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">Flight No</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">Flight Date</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">Flight Time</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">Buffer Time</Typography>
                </div>}
                {(showMore && trip?.flightno) && <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center', border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF' }}>
                    <Typography flex={0.25}  fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">{trip?.flightno}</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">{trip?.flightdate}</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">{trip?.flighttime}</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={12} textAlign="center">{trip?.buffer_time}</Typography>
                </div>}
                {(showMore && trip?.pickup_loc_type === "ferry") && <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center' }}>
                    <Typography flex={0.5} color={'#0A2638'}  fontFamily="Nunito" fontSize={12} textAlign="center">Cruise Name</Typography>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">Time of Disembarkment</Typography>
                </div>}
                {(showMore && trip?.pickup_loc_type === "ferry") && <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center', border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF' }}>
                    <Typography flex={0.5} color={'#0A2638'}  fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.cruise_no}</Typography>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.disembarkment}</Typography>
                </div>}
                {showMore && <div style={{ display: 'flex',  flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start' }}>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="left" paddingLeft={1}>{"Scheduled Pickup Time"}</Typography>
                    <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5 }}>
                        <Typography color={'#0A2638'} flex={0.5}  fontFamily="Nunito" fontSize={12} paddingLeft={1}>{trip?.arrival === "Arrival" ? trip?.buffer_time ? moment(`${trip?.flightdate} ${trip?.flighttime}`, "DD-MM-YYYY hh:mm A").add(parseInt(trip?.buffer_time), 'minutes').format("hh:mm A") : trip?.flightdate ? `${trip?.flightdate} ${trip?.flighttime}` : moment(`${trip?.pickupdate} ${trip.pickuptime}`, "YYYY-MM-DD hh:mm A").format("DD-MM-YYYY hh:mm A") : moment(`${trip?.pickupdate} ${trip.pickuptime}`, "YYYY-MM-DD hh:mm A").format("DD-MM-YYYY hh:mm A")}</Typography>
                    </div>
                </div>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                    <img src={Blue} width={30} height={30} />
                    <Typography color={'#0A2638'} fontWeight={700} fontFamily="body" numberOfLines={2} flexWrap="wrap" fontSize={12} ml={2}>{trip?.location_from}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '90%', paddingLeft: 10, borderLeftWidth: 1, borderColor: 'red', border: '1px solid red', borderWidth: '0px 0px 0px 2px', position: 'relative', minHeight: 10 }}>
                    {aditionalStops?.map(stop => (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                        <img src={Blue} width={20} height={20} />
                        <Typography color={'#0A2638'} fontWeight={700} fontFamily="body" numberOfLines={2} fontSize={10} ml={2}>{stop?.cityname}</Typography>
                    </div>))}
                    <KeyboardArrowDownIcon style={{ color: 'red', position: 'absolute', left: -12.7, bottom: -10 }} />
                </div>
                
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                    <img src={Red} width={30} height={30} />
                    <Typography color={'#0A2638'} fontWeight={700} fontFamily="body" numberOfLines={2} fontSize={12} ml={2} flexWrap="wrap">{trip?.location_to}</Typography>
                </div>
                {showMore && 
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                <div style={{ width: '100%', border: '1px solid #EEEEEE' }}></div>
                <div style={{ display: 'flex',  flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start' }}>
                    <Typography flex={0.5} color={'#0A2638'}  fontFamily="Nunito" fontSize={12} textAlign="left" paddingLeft={1}>{"Passenger Name"}</Typography>
                    <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5 }}>
                        <Typography color={'#0A2638'} flex={0.5}  fontFamily="Nunito" fontSize={12} paddingLeft={1}>{trip?.arrival === "Arrival" ? trip?.buffer_time ? moment(`${trip?.flightdate} ${trip?.flighttime}`, "DD-MM-YYYY hh:mm A").add(parseInt(trip?.buffer_time), 'minutes').format("hh:mm A") : trip?.flightdate ? `${trip?.flightdate} ${trip?.flighttime}` : moment(`${trip?.pickupdate} ${trip.pickuptime}`, "YYYY-MM-DD hh:mm A").format("DD-MM-YYYY hh:mm A") : moment(`${trip?.pickupdate} ${trip.pickuptime}`, "YYYY-MM-DD hh:mm A").format("DD-MM-YYYY hh:mm A")}</Typography>
                    </div>
                </div>
                <div style={{ display: 'flex',  flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start' }}>
                    <Typography color={'#0A2638'} flex={0.5}  fontFamily="Nunito" fontSize={12} textAlign="left" paddingLeft={1}>{"Contact No"}</Typography>
                    <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5 }}>
                        <Typography color={'#0A2638'} flex={0.5}  fontFamily="Nunito" fontSize={12} paddingLeft={1}>{trip?.phone}</Typography>
                    </div>
                </div>
                <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center' }}>
                    <Typography color={'#0A2638'} flex={0.25}  fontFamily="Nunito" fontSize={12} textAlign="center">Adults</Typography>
                    <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={12} textAlign="center">Childs</Typography>
                    <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={12} textAlign="center">Luggage</Typography>
                    <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={12} textAlign="center">Bags</Typography>
                </div>
                <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center', paddingBottom: 5 }}>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.adults}</Typography>
                        </div>
                    
                    </div>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.childrens ? trip?.childrens : 0}</Typography>
                        </div>
                    
                    </div>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.suitcase ? trip?.suitcase : 0}</Typography>
                        </div>
                    
                    </div>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.carryon ? trip?.carryon : 0}</Typography>
                        </div>
                    
                    </div>
                </div>
                <Typography fontFamily="Nunito" fontSize={12} marginLeft={4} color={"red"} textAlign="flex-start">{"Extra"}</Typography>
                <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center' }}>
                    <Typography color={'#0A2638'} flex={0.25}  fontFamily="Nunito" fontSize={12} textAlign="center">Baby Seats</Typography>
                    <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={12} textAlign="center"></Typography>
                    <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={12} textAlign="center">Booster Seats</Typography>
                    <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.itenary ?'Itinerary' : ''}</Typography>
                </div>
                <div style={{ display: 'flex',  flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center', paddingBottom: 5 }}>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.baby_seats}</Typography>
                        </div>
                    
                    </div>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                       
                    
                    </div>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.booster_seats}</Typography>
                        </div>
                    
                    </div>
                    <div style={{ display: 'flex',  flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                        {trip?.itenary && <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 9, paddingRight: 9 }}>
                            <a href={trip?.itenary}><CloudDownload style={{ fontSize: 14, cursor: 'pointer' }}  /></a>
                        </div>}
                    
                    </div>
                </div>
                <div style={{ display: 'flex',  flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start', paddingBottom: 20 }}>
                    <Typography flex={0.5} color={'#0A2638'}  fontFamily="Nunito" fontSize={12} textAlign="left" paddingLeft={1}>{"Special Instructions"}</Typography>
                    <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5 }}>
                        <Typography color={'#0A2638'} flex={0.5}  fontFamily="Nunito" fontSize={12} paddingLeft={1}>{trip?.specialinstructions}</Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                {trip?.noshowimages && trip?.noshowimages?.map((img, i) => (
                    <img src={`${IMAGE_URL}${img.image}`} width={50} height={50} />
                ))}
                </div>
                </div>}
                {!showMore && <div style={{ width: '100%', border: '1px solid #EEEEEE', display: 'flex', justifyContent: 'center', backgroundColor: '#00000029', paddingTop: 3, paddingBottom: 3 }}>
                    <Typography  color={'#0A2638'} fontWeight={600} fontFamily="Nunito" fontSize={12} >Trip ID: #{trip?.id}</Typography>
                </div>}
                {renderTripButton(parseInt(trip?.status))}
                {noShow && <>
                    <CameraFeed sendFile={UploadImages}  />
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {files && files?.map((file, index) => (
                            <div style={{ position: 'relative', marginTop: 20 }}>
                                <img src={URL.createObjectURL(file)} width={100} height={100} />
                                <CloseOutlined style={{ position: 'absolute', top: -10, right: 20, fontSize: 20, color: 'red', cursor: 'pointer', fontWeight: 'bold' }} onClick={() =>deleteImage(index)} />
                            </div>
                        ))}
                        </div>
                        {(files && files.length > 0) && <Button style={{ backgroundColor: '#B6A269', color: '#fff', marginBottom: 5 }} onClick={saveNoShow}>
                            Update
                        </Button>}
                    </>
                    
                </>}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to start Trip?.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={makeActiveTrip} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                //onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default DriverTrip