import React, { useState, useEffect } from 'react'
import './style.scss'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { getTransactions } from '../../Redux/actions/topupActions'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Stack, Typography } from '@mui/material';
import CustomDate from '../../components/CustomDate';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

const TransactionsList = ({fromDate, toDate}) => {
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.auth)
	const [datas, setDatas] = useState([])



	console.log({datas})
	
	// const [fromDate, setFromDate] = useState('')
	// const [toDate, setToDate] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const { transactionLists, error, newTopup } = useSelector(state => state.topup)

	useEffect(() => {
		if (transactionLists) {
			//const indexOfLastPost = currentPage * 10;
			//const indexOfFirstPost = indexOfLastPost - 10;
			//const currentPosts = (transactionLists || []).slice(indexOfFirstPost, indexOfLastPost);
			setDatas(transactionLists)
		}	
	}, [transactionLists,fromDate=== '' && toDate === '' ? fromDate : toDate])


	useEffect(() => {
		if(fromDate && toDate){
			let lists = transactionLists.filter(list => moment(list?.created_at).format('YYYY-MM-DD') === moment(fromDate).format("YYYY-MM-DD") && moment(list?.created_at).format("YYYY-MM-DD") === moment(toDate).format("YYYY-MM-DD HH:mm:ss") || moment(list?.created_at).format('YYYY-MM-DD') >= moment(fromDate).format("YYYY-MM-DD") && moment(list?.created_at).format("YYYY-MM-DD") <= moment(toDate).format("YYYY-MM-DD HH:mm:ss"))
			setDatas(lists)
		}	
	}, [fromDate, toDate])
	

	const pageChanged = (page) => {
		setCurrentPage(page)	
		const indexOfLastPost = page * 10;
		const indexOfFirstPost = indexOfLastPost - 10;
		const currentPosts = (transactionLists || []).slice(indexOfFirstPost, indexOfLastPost);
		setDatas(currentPosts)
	}

	useEffect(() => {
		let params = {
            parent_id : user?.parentDetails ? user?.parentDetails?.id : user?.id,
            child_id : user?.id
        }
		dispatch(getTransactions(params))
	}, [])



	useEffect(() => {
		let params = {
            parent_id : user?.parentDetails ? user?.parentDetails?.id : user?.id,
            child_id : user?.id
        }
		dispatch(getTransactions(params))
	}, [newTopup])
	

	const columns = [
		{ 
			field: 'transaction_id', 
			headerName: 'TRANSACTION ID', 
			flex: 2,
			minWidth: 150 
		},
		{ 
			field: 'deposit_date', 
			headerName: 'TRANSACTION DATE', 
			flex: 2,
			minWidth: 200, 
			renderCell: (params) => {
				if(params?.row?.deposit_date){
					return moment(params?.row?.deposit_date, "YYYY-MM-DD").format("DD-MM-YYYY");
				}
				else{
					return moment(params?.row?.created_at, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
				}
			}, 
		},
		{ 
			field: 'type', 
			headerName: 'TYPE', 
			flex: 2,
			minWidth: 150,
			renderCell: (params) => {
				return params.row.topup > 0 ? 'Topup' : 'Debit';
			}, 
		},
		{ 
			field: 'trip id', 
			headerName: 'TRIP ID', 
			flex: 2,
			minWidth: 150,
			renderCell: (params) => {
				return params?.row?.trip ? params?.row?.trip?.map(res => res?.id) : '-'
			}, 
		},
		{
			field: 'status',
			headerName: 'STATUS',
			flex: 2,
			minWidth: 150,
			renderCell: (params) => {
				return params.row.status === 1 ? 'Approved' : 'Pending';
			},
		},
		{
			field: 'amount',
			headerName: 'AMOUNT(S$)',
			flex: 3,
			minWidth: 200,
			renderCell: (params) => {
				return params.row.topup > 0 ? parseFloat(params.row.topup).toFixed(2) : parseFloat(params.row.debit).toFixed(2);
			}, 
		},
	];


	return (
		<div>
			 {/* <div style={{ display: 'flex'  , flexDirection: 'column', marginTop: 15, marginBottom: 15}} >
				<Stack style={{ display: 'flex', paddingTop: 5, paddingBottom: 10, paddingRight: 10 }} flexDirection={{ xs: "column" , md:'row' }} >
					<Grid xs={12} md={3} mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }}>
						<CustomDate label={"From"} changeDate={(value) => setFromDate(value)}  />
					</Grid>
					<Grid xs={12} md={3}>
						<CustomDate label={"To"} changeDate={(value) => setToDate(value)} />
					</Grid>
				</Stack>
			</div> */}
			<TableContainer component={Paper}>
				<div style={{ height: 450, width: "100%", border: 0 }}>
					<DataGrid
						rows={datas}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[10]}
					/>
				</div>
			</TableContainer>
		</div>
	)
}

export default TransactionsList
