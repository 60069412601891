import React, { Fragment, useEffect, useState } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectedVehicle from '../../components/SelectedVehicle'
import TopBar from '../../components/TopBar'
import { CircularProgress, Typography, Button } from '@mui/material';
import './style.scss'
import PrintImg from '../../assets/images/IconsNew/print.png'
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import { BOOKING_DATA, RESET_BOOKING } from '../../Redux/constants/bookingConstants';
import Lottie from "lottie-react";
import Checked from "../../assets/images/checked.json";
import { PlusOneOutlined } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { newBooking } from '../../Redux/actions/bookingActions';
import PrintReceipt from './PrintReceipt';
import { PDFDownloadLink } from '@react-pdf/renderer';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';

const Done = ({ head }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const [booking, setBooking] = useState(null)
    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(false);

    const { orderData, bookingSuccess, couponDetails, couponApplied, bookingType, bookingResponse } = useSelector(state => state.booking)
    const { user } = useSelector(state => state.auth)

    const dispatch = useDispatch();


    // useEffect(() => {
    //     //setBooking(bookingData);
    //     //setVehicle(selectedVehicle);
    //     localStorage.removeItem("booking");
    //     setTimeout(() => {
    //         dispatch({
    //             type: RESET_BOOKING
    //         })
    //     }, 5000);

    // }, [])

    const newBooking = () => {
        dispatch({
            type: RESET_BOOKING
        })
        navigate('/newbooking');
    }



    const downlodeTripInvoice = async (id) => {

        setLoading(true);
        await customAxios.post(`generateinvoice`, {
            trip_id: id
        })
            .then(async response => {
              
                const url = response.data.data;
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', 'file.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            })
            .catch(async error => {
                setLoading(false);
                toast.error(error)

            });


    }


    return (
        <Layout head={head}>
            <WorkFlow activeStep={5} mode={'ok'}/>
            {vehicle && booking && <SelectedVehicle data={vehicle} booking={booking} />}
            <Grid flexDirection={{ xs: 'column', md: 'row' }} style={{ display: 'flex', justifyContent: 'center', paddingTop: '2%' }} marginBottom={2} >
                <Button variant="filled" style={{ borderRadius: 0, height: 40 }} >
                    <Lottie animationData={Checked} style={{ height: 50, width: 50 }} loop={true} /><span className='successText' >Your trip has been successfully booked !</span> </Button>
            </Grid>
            <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', overflow: 'hidden', paddingLeft: '1%' }}>
                <Button type="submit" variant="filled" onClick={() => newBooking()} endIcon={<Add style={{ color: 'white' }} />} style={{ backgroundColor: '#333333', borderRadius: 0, marginRight: 10, padding: 5, height: 28 }} >
                    <Typography className='buttonText'>NEW BOOKING</Typography>
                </Button>
                {/* <PDFDownloadLink
                    document={<PrintReceipt order={bookingResponse} user={user} />}
                    fileName="invoice"
                    style={{ color: '#333333' }}
                > */}
                <Button type="submit" variant="filled" endIcon={<img src={PrintImg} style={{ width: 25, height: 25 }} />} style={{ backgroundColor: '#333333', borderRadius: 0, padding: 5, height: 28, }} onClick={() => downlodeTripInvoice(bookingResponse?.id[0])} >
                    <Typography className='buttonText'>PRINT Invoice</Typography>
                </Button>
                {/* </PDFDownloadLink> */}
            </Grid>
            <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', overflow: 'hidden', paddingLeft: '1%', marginTop: 10 }}>
                {/* <Button type="submit" variant="filled" onClick={() => newBooking()} endIcon={<img src={PrintImg} style={{ width: 25, height: 25 }} />  } style={{backgroundColor:'#333333',borderRadius:15,height:50,width:200,marginRight:'3%'}} >
                <Typography className='buttonText'>PRINT RECEIPT</Typography>
            </Button> */}
            </Grid>
        </Layout>
    )
}

export default Done
