import React, { useState } from 'react'
import { FormGroup, IconButton, InputAdornment } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
	noBorder: {
	  borderRadius: 0,
	  border: 0,
	},
	resize:{
		fontSize:12,
		height:30,
		padding:'0px 0px 0px 5px',
	},
}));

const InputField = ({ fieldName, fieldType, fieldLabel, placeholder, width, fontSize, control, error, children, defaultValue, rows, multiline, min, readonly, helperText, required, marginTop }) => {

	const [showPass, setShowPass] = useState(false)

	

	  const classes = useStyles();

	return (
		<FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }}  >
			<Typography
			  className='formText' 
			  sx={{ fontSize:  '12px' }} >{fieldLabel}{required && <span style={{ color: 'red' }}>*</span>}</Typography>
			<Controller
				name={fieldName}
				control={control ? control : null}
				render={({ field }) => <TextField

					disabled={readonly}
					size='small'
					helperText={helperText}
					defaultValue={defaultValue}
					error={error?.message ? true : false}
					multiline={multiline ? multiline : null}
					minRows={rows ? rows : 1}
					InputProps={{
						inputProps: {
							min: min,
						},
						disableUnderline: true,
						//classes:{notchedOutline:classes.noBorder},
						endAdornment: fieldType === "password" && (
							<IconButton  style={{ height: 20 }} onClick={() => setShowPass(!showPass)}>
								{showPass ? <Visibility /> : <VisibilityOff />   }
							</IconButton>
						),
						classes: {
							input: classes.resize
						}
					}}
					style={{ backgroundColor: '#fff', justifyContent: 'center', border: '1px solid #ccc', borderRadius: 4, height: 30 }}
					{...field}
					variant='standard' className='formText'
					sx={{
						"& .MuiFilledInput-underline:before": {
							borderBottom: "none"
						},
						"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
							borderBottom: "none"
						},
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "#000000",
						},
					}}
					type={!showPass ? fieldType : 'text'} 
					placeholder={placeholder}
				>{children}</TextField>}
			/>
			{error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
		</FormGroup>
	)
}

export default InputField
