import React, { Fragment, useContext, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Card, Typography } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { height } from '@mui/system';
import LocationImg from '../../assets/images/Icons/placeholder.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import DistanceImg from '../../assets/images/Icons/measure-distance.png'
import { useLocation, useNavigate, UNSAFE_NavigationContext  } from 'react-router-dom';
import SelectedVehicle from '../../components/SelectedVehicle'
import TopBar from '../../components/TopBar'
import PaymentDetails from './PaymentDetails'
import { useSelector } from 'react-redux'
import AllTripsPayments from './AllTripsPayments'
import Bookings from '../../components/Bookings'
import AddedVehicle from '../../components/AddedVehicle'
const Payment = ({ head, history }) => {
    const location = useLocation();
    const navigate = useNavigate()
    //const { selectedVehicle, orderData } = useSelector(state => state.booking)
    const { selectedBooking: booking, currentVehicle: data, orderData } = useSelector(state => state.booking)

    useEffect(() => {
        if (!orderData) {
            navigate("/newbooking");
        }
    }, [orderData])
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        window.onpopstate = () => {
            navigate("/payment");
          }
      }, []);
 
return (
    <Layout head={head}>
        {(user) &&
            <WorkFlow activeStep={3} />
        }
        {(!user) &&
            <WorkFlow activeStep={3} />
        }
        {/* <SelectedVehicle data={data} booking={booking} /> */}
        {/* <AllTripsPayments /> */}
        <Grid  paddingY={5} flexDirection={{ xs: 'column-reverse',  md: "row"}} container >
            <Grid item md={8}>
                <Grid
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        overflow: 'hidden',
                        paddingLeft: '1%',
                        paddingTop: 10,
                        paddingBottom: 5
                    }}>
                    <h4 class='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#B6A269' }}>Payment</span> Details</h4>
                </Grid>
                <PaymentDetails />
            </Grid>
            <Grid item xs={12} md={4} marginTop={{ xs:0, md: 9 }} >
                <AddedVehicle page="payment" />
            </Grid>
        </Grid>
        
        
    </Layout>
)
}

export default Payment
