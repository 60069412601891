import React, { useState, useEffect, useMemo } from 'react'
import './style.scss'
import { Typography, MenuItem, Box, FormControl, FormLabel, Checkbox, FormGroup } from '@mui/material'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    Button
} from "@mui/material";
import InputField from '../../components/InputField';
import { useLocation } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DropdownInput from '../../components/DropdownInput';
import Add from '@mui/icons-material/Add';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Vehicle1Img from '../../assets/images/Vehi1.jpg'
import DateInput from '../../components/DateInput';
import reactotron from '../../ReactronConfig';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_BOOKING, BOOKING_INPUT, LOADING, ORDER_DATA, PASSENGERS_DETAILS, RESET_BOOKING_DATA, RESET_ORDER_DATA, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants';
import uuid from 'react-uuid';
import PhoneInputs from '../../components/PhoneInputs';
import countryList from 'react-select-country-list'
import { isArray } from 'lodash';
import BorderBox from '../../components/BorderBox';
import Itinary from './Itinary';
import customAxios from '../../CustomAxios';
import FileInput from '../../components/FileInput';
import { toast } from 'react-toastify';
import TimePicker from '../../components/TimePicker';
import { Image, Visibility, VisibilityOff } from '@mui/icons-material';
import ImageInput from '../../components/ImageInput';


const DetailsForm = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [services, setServices] = useState(null)

    const options = useMemo(() => countryList().getData(), [])



    const { user, additionalStops } = useSelector(state => state.auth)
    const { orderData, passengers, bookingType, selectedBooking: booking, currentVehicle: data, selectedVehicle, airportType, itenary } = useSelector(state => state.booking)


    console.log({ user })

    // const state = useSelector(state => state.booking)

    const { vehicleAvailability } = useSelector(state => state.mytransfer)
    const { markupLists } = useSelector(state => state.markup)


    //console.log({vehicleAvailability})









    const dispatch = useDispatch();

    const [methods, setMethods] = useState('')
    const [country, setCountry] = useState('SG')
    const [arival, setArival] = useState('international');
    const [bufferList, setBufferList] = useState([]);
    const [buffer, setBuffer] = useState(null)
    const [loading, setLoading] = useState(false)






    const [flightArrival, setFlightArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Flight Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Flight Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Flight Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Flight Arrival'

        },
    ])




    const uploadFiles = async (file) => {


        console.log({file})

        dispatch({
            type: LOADING,
            payload: true
        })

        let formData = new FormData();
        formData.append("itenary", file)

        await customAxios.post(`uploaditenary`, formData)
            .then(async response => {

                dispatch({
                    type: LOADING,
                    payload: false
                })
                dispatch({
                    type: BOOKING_INPUT,
                    payload: {
                        prop: 'itenary',
                        value: response?.data?.data
                    }
                })
            })
            .catch(async error => {
                dispatch({
                    type: LOADING,
                    payload: false
                })
                toast.error(error)

            });
    }

    useEffect(() => {
        if (data?.addOnServices) {
            let services = data?.addOnServices?.map(serv => {
                return {
                    id: serv?.id,
                    name: serv?.addonservice?.name,
                    price: serv?.rate
                }
            })

            setServices(services)
        }
    }, [])


    useEffect(() => {
        window.scrollTo(0, 300)
    }, [])



    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


    const schema = yup.object({
        passenger_name: yup.string().required("First name Required").max(30, 'Maximum character exceeded'),
        passenger_lname: yup.string().required("Last name Required").max(30, 'Maximum character exceeded'),
        mobile: yup.string().required("Phone Number Required").max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email required').max(30, 'Maximum character exceeded'),
        arrival_method: yup.string(),
        flight_no: vehicleAvailability?.pickupType === "airport" ? yup.string().required('Flight Number required') : yup.string(),
        luggage: yup.number().max(bookingType === "PAL" ? data?.vehicledetails?.vehicle_type?.no_check_in : selectedVehicle?.suitcases).typeError(`Maximum luggage exceed`),
        // arival: yup.string().typeError("Arival method required").when('arrival_method', (arrival_method, schema) => {
        //     return arrival_method === "flight" ? schema.required("Arrival method required")   : schema.string();
        // }),
        // flight_date: yup.string().typeError("Flight date & time required").when('arrival_method', (arrival_method, schema) => {
        //     return arrival_method === "flight" ? schema.required("Flight date & time required")   : schema.min(0);
        // }),
        //flightdate: yup.string().required("Flight date & time required")
        hand_carry: yup.number().max(99, 'Maximum number exceeded').typeError('')
    }).required();

    const { register, handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: passengers ? passengers : {
            id: uuid(),
            flight_date: orderData?.flight_date ? orderData?.flight_date : moment(booking?.pickupdate).toDate(),
            country: 'SG',
            passenger_name: user?.role === "customer" ? user?.name : '',
            email: user?.role === "customer" ? user?.email : '',
            mobile: user?.role === "customer" ? `${user?.country_code}${user?.phone}` : '',
            arival: 'international',
            passenger_lname: user?.role === "customer" ? user?.lastname : ''
        },
        mode: 'onChange'
    });






    const getBufferTime = async () => {
        setLoading(true);
        await customAxios.get(`auth/getbuffertime/${selectedVehicle.vehicle_type_id
            }`)
            .then(async response => {
                console.log({ response: response.data.data })
                setBuffer(response?.data?.data?.[0]?.buffer_time)
                setBufferList(response?.data?.data)
                setValue('time_related', response?.data?.data?.[0]?.buffer_time)
                setLoading(false);
            })
            .catch(async error => {
                setLoading(false);
                toast.error(error)

            });

    }

    useEffect(() => {
        if (booking?.location_from?.type === "airport") {
            getBufferTime();
        }
    }, [booking?.location_from?.type === "airport"])



    const onSubmit = (datas) => {


        // dispatch({
        //     type: PASSENGERS_DETAILS,
        //     payload: datas
        // })
        if (bookingType === "PAL") {
            populateOrderData(datas)
        }
        else if (bookingType === "MYTRANSFER") {
            populateMyTransferData(datas)
        }
        dispatch({
            type: ADD_BOOKING
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'itenary',
                value: null
            }
        })

        if (user) {
            navigate('/payment')
        }
        else {
            navigate('/loginregister')
        }
        dispatch({
            type: RESET_BOOKING_DATA
        })
        localStorage.removeItem("booking");
    };

    const addMoreBooking = async (data) => {
        populateOrderData(data)
        await localStorage.removeItem("booking");
        dispatch({
            type: ADD_BOOKING
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'itenary',
                value: null
            }
        })
        navigate('/newbooking')
    }

    const populateMyTransferData = (datas) => {




        let inputData = {
            customer_reference: datas?.reference_number,
            arrival: booking?.type === 'point' ? (booking?.location_from?.type === 'airport' || vehicleAvailability?.pickupType === "airport") ? 'Arrival' : booking?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: datas?.id,
            location_from: booking?.location_from?.address,
            location_to: booking?.location_to?.address,
            baggage_protection: (booking?.location_to?.type === "airport" && country === "SG") ? true : false,
            name: `${datas?.passenger_name} ${datas?.passenger_lname}`,
            customerFirstName: datas?.passenger_name,
            customerLastName: datas?.passenger_lname,
            country: datas?.country,
            customerCountry: datas?.country,
            email: datas?.email,
            customerEmail: datas?.email,
            phone: datas?.mobile,
            customerPhone: datas?.mobile,
            cruise_no: datas?.cruise_no,
            disembarkment: moment(datas?.disembarkment).format("hh:mm A"),
            mobile: datas?.mobile,
            namesign: "",
            nationality: "",
            passport: "",
            adults: booking?.adults ? booking?.adults : 0,
            childrens: booking?.childrens ? booking?.childrens : 0,
            minors: booking?.minors ? booking?.minors : 0,
            booster_seats: booking?.minors ? booking?.minors : 0,
            baby_seats: booking?.baby ? booking?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.instructions ? datas?.instructions : 0,
            pickupdate: moment(booking?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(booking?.pickupdate).format("hh:mm A"),
            pickup_loc_type: null,
            flightno: getValues('flight_no') && datas?.flight_no ? datas?.flight_no : null,
            flighTiming: getValues('flight_no') && datas?.flight_date ? datas?.flight_date : null,
            flightdate: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
            flighttime: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
            time_related: getValues('flight_no') && datas?.time_related ? datas?.time_related : null,
            buffer_time: getValues('flight_no') && datas?.time_related ? datas?.time_related : null,
            supplier_id: null,
            vehicle_type: null,
            vehicle_id: null,
            driver_id: null,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: data?.currencyId ? data?.currencyId : "SGD",
            usertype: user ? user?.role : "guest",
            stops: [],
            stopCount: 0,
            payment_type: "card",
            arival: arival ? arival : null,
            booking_data: booking,
            selectedVehicle: selectedVehicle,
            bookingType: bookingType,
            addonItem: null,
            addonservices: null,
            taxPer: null,
            vehicletype: null,
            service_type: null,
            vehicle_image: selectedVehicle?.imageURL,
            agentMarkup: selectedVehicle?.agentmarkup,
            base_rate: selectedVehicle?.price,
            markup: selectedVehicle?.adminMarkup,
            tax: selectedVehicle?.taxValue,
            total_price: selectedVehicle?.total,
            toll: 0,
            extraCharges: selectedVehicle?.extraRate,
            addonService: 0,
            extras: selectedVehicle?.extras,
            itenary: booking?.type === "hour" ? itenary?.itenary : null,
            itenary_id: booking?.type === "hour" ? itenary?.id : null,
        }



        dispatch({
            type: ORDER_DATA,
            payload: inputData
        })
    }

    const populateOrderData = (datas) => {

        let agentmarkup = 0;
        let adminMarkup = 0
        let tax = 0;
        let total = 0;
        let additionalStop = 0;
        let baserate = 0;
        let extras = 0;
        let extraCharges = null;
        let addonService = 0;
        let toll = data?.tollrate ? parseFloat(data?.tollrate) : 0;

        let service = services?.filter(serv => serv?.checked)

        service?.map(serv => {
            addonService += parseFloat(serv?.price)
        })

        additionalStop = booking?.stops ? additionalStops ? booking?.stops?.length * additionalStops : booking?.stops?.length * 20 : 0;
        baserate = booking?.type === "hour" ? (parseFloat(data?.rate) * parseFloat(booking?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop

        extraCharges = data?.addonItem?.map(extra => {
            if (extra?.rate_type === "percentage") {
                extras += booking?.type === "hour" ? (((parseFloat(baserate) / 100) * parseFloat(extra?.rate)) * parseFloat(booking?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
                return {
                    id: extra?.addonitem?.id,
                    name: extra?.addonitem?.name,
                    price: booking?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(booking?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
                }
            }
            else {
                extras += booking?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(booking?.duration)) : parseFloat(extra?.rate)
                return {
                    id: extra?.addonitem?.id,
                    name: extra?.addonitem?.name,
                    price: booking?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(booking?.duration)) : parseFloat(extra?.rate)
                }
            }
        })

        if (user?.role === "agent" && markupLists?.type === "fixed") {




            if (markupLists?.status === 1) {
                agentmarkup = parseFloat(markupLists?.value);
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.defaultMarkup?.value)
            }



            tax = ((baserate + adminMarkup + agentmarkup + extras + addonService + toll) / 100) * parseFloat(data?.tax?.value)

            total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll;
        }
        else {



            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = ((baserate + extras + addonService + toll) / 100) * parseFloat(markupLists?.value)
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            tax = ((baserate + adminMarkup + agentmarkup + extras + addonService + toll) / 100) * parseFloat(data?.tax?.value)

            total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll;
        }

        let selected = {
            ...data,
            rate: baserate,
            adminMarkup,
            agentmarkup,
            extraRate: extras,
            total: total,
            taxValue: tax,
            extraCharges,
            services: service,
            addonService
        }

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: booking,
                selectedVehicle: selected,
                id: 'current'
            }
        })

        if (orderData) {
            let order = orderData.filter(or => or.id !== datas.id);
            dispatch({
                type: RESET_ORDER_DATA,
                payload: order
            })
        }



        let stops = booking?.stops?.map(stop => {
            return {
                cityname: stop?.stop_name?.address,
                latitude: stop?.stop_name?.latitude,
                longitude: stop?.stop_name?.longitude,
            }
        })





        console.log({ stops }, "STOPS")


        let inputData = {
            customer_reference: datas?.reference_number,
            arrival: booking?.type === 'point' ? booking?.location_from?.type === 'airport' ? 'Arrival' : booking?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: datas?.id,
            location_from: booking?.location_from?.address,
            location_to: booking?.location_to?.address,
            baggage_protection: (booking?.location_to?.type === "airport" && country === "SG") ? true : false,
            name: `${datas?.passenger_name} ${datas?.passenger_lname}`,
            country: datas?.country,
            email: datas?.email,
            phone: datas?.mobile,
            mobile: datas?.mobile,
            namesign: "",
            nationality: "",
            passport: "",
            adults: booking?.adults ? booking?.adults : 0,
            childrens: booking?.childrens ? booking?.childrens : 0,
            cruise_no: datas?.cruise_no,
            disembarkment: moment(datas?.disembarkment).format("hh:mm A"),
            minors: booking?.minors ? booking?.minors : 0,
            booster_seats: booking?.minors ? booking?.minors : 0,
            baby_seats: booking?.baby ? booking?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.instructions ? datas?.instructions : 0,
            pickupdate: moment(booking?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(booking?.pickupdate).format("hh:mm A"),
            pickup_loc_type: datas?.arrival_method ? datas?.arrival_method : null,
            flightno: getValues('flight_no') && datas?.flight_no ? datas?.flight_no : null,
            flighTiming: getValues('flight_no') && datas?.flight_date ? datas?.flight_date : null,
            flightdate: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
            flighttime: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
            time_related: getValues('flight_no') && datas?.time_related ? datas?.time_related : null,
            buffer_time: getValues('flight_no') && datas?.time_related ? datas?.time_related : null,
            supplier_id: data?.supplier_id ? data?.supplier_id : null,
            vehicle_type: data?.vehicledetails?.vehicle_type?.id ? data?.vehicledetails?.vehicle_type?.id : null,
            vehicle_id: data?.vehicledetails?.veh_details?.vehicle_id ? data?.vehicledetails?.veh_details?.vehicle_id : null,
            driver_id: data?.vehicledetails?.veh_details?.driver_id ? data?.vehicledetails?.veh_details?.driver_id : null,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: data?.currencyId ? data?.currencyId : "SGD",
            usertype: user ? user?.role : "guest",
            stops: stops ? stops : [],
            stopCount: stops ? stops?.length : 0,
            payment_type: "card",
            arival: arival ? arival : null,
            booking_data: booking,
            selectedVehicle: selected,
            bookingType: bookingType,
            addonItem: extraCharges,
            addonservices: service,
            taxPer: data?.tax?.value,
            vehicletype: data?.vehicledetails?.vehicle_type?.vehicle_type,
            service_type: data?.service_type,
            vehicle_image: data?.vehicledetails?.vehicle_images?.length > 0 ? `${data?.imageBasePath}${data?.vehicledetails?.vehicle_images[0].path}` : null,
            agentMarkup: agentmarkup,
            base_rate: baserate,
            markup: adminMarkup,
            tax: tax,
            total_price: total,
            toll: toll,
            extraCharges: extras,
            addonService: addonService,
            itenary: booking?.type === "hour" ? itenary?.itenary : null,
            itenary_id: booking?.type === "hour" ? itenary?.id : null,
        }


        if (booking?.bookingType === "return") {
            let order = orderData?.find(or => or.id === datas.id);
            if (!order) {
                let returnData = {
                    ...inputData,
                    id: uuid(),
                    location_from: inputData?.location_to,
                    location_to: inputData?.location_from,
                    pickupdate: moment(booking?.returndate).format("YYYY-MM-DD"),
                    pickuptime: moment(booking?.returndate).format("hh:mm A"),
                    booking_data: {
                        ...inputData.booking_data,
                        location_from: inputData.booking_data?.location_to,
                        location_to: inputData.booking_data?.location_from,
                        pickupdate: inputData.booking_data?.returndate
                    }
                }
                dispatch({
                    type: ORDER_DATA,
                    payload: returnData
                })
            }
        }

        dispatch({
            type: ORDER_DATA,
            payload: inputData
        })

    }

    const addAddOnService = (checked, id) => {


        let newServ = services?.map(add => {
            if (id === add?.id) {
                return {
                    ...add,
                    checked: checked
                }
            }
            else {
                return add
            }
        })

        setServices(newServ)

        let selectedServices = newServ?.filter(add => add.checked === true)
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: selectedServices
            }
        })
    }

    return (
        <Container maxWidth="xl" >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box width={{ xs: "100%", md: "100%" }} paddingLeft={{ xs: 0, md: "0%" }} paddingRight={{ xs: 0, md: "0%" }}>
                    <BorderBox title={"Passenger Details"}>
                        <Grid container spacing={.5} >
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="First Name"
                                    fieldName="passenger_name"
                                    fieldType="text"
                                    control={control}
                                    error={errors.passenger_name}
                                    required
                                    marginTop={0}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Last Name"
                                    fieldName="passenger_lname"
                                    fieldType="text"
                                    control={control}
                                    error={errors.passenger_lname}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Email Address (Booking Notification)"
                                    fieldName="email"
                                    control={control}
                                    error={errors.email}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <DropdownInput
                                    fieldLabel="Country"
                                    fieldName="country"
                                    control={control}
                                    error={errors.country}
                                    changeValue={(e) => {
                                        setCountry(e.target?.value)
                                        if (e?.target?.value === "SG") {
                                            setArival('international')
                                        }

                                    }}
                                >
                                    <MenuItem disabled value="" className='formText'>
                                        Select
                                    </MenuItem>
                                    {options?.map(opt => (
                                        <MenuItem className='formText' value={opt?.value}>{opt?.label}</MenuItem>
                                    ))}
                                </DropdownInput>
                            </Grid>
                            <Grid xs={12} md={6} sx={{ flexGrow: 1 }} >
                                <PhoneInputs
                                    fieldLabel="Mobile"
                                    fieldName="mobile"
                                    control={control}
                                    error={errors.mobile}
                                    required
                                />
                            </Grid>
                            <Grid xs={12} md={3} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Check-in Luggage"
                                    fieldName="luggage"
                                    fieldType={"number"}
                                    min={1}
                                    control={control}
                                    error={errors.luggage}
                                />
                            </Grid>
                            <Grid xs={12} md={3} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Hand Carry"
                                    fieldName="hand_carry"
                                    fieldType={"number"}
                                    min={1}
                                    control={control}
                                    error={errors.hand_carry}
                                />
                            </Grid>

                            <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Special Instructions"
                                    fieldName="instructions"
                                    control={control}
                                    // multiline={true}
                                    rows={1}
                                    error={errors.instructions}
                                />
                            </Grid>
                            {(user?.role === 'agent' || user?.role === 'corporate') ? <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Reference Number"
                                    fieldName="reference_number"
                                    fieldType={"text"}
                                    control={control}
                                    error={errors.reference_number}
                                />
                            </Grid> : ''}
                            {booking?.type === "hour" && <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                {/* <FileInput
                                    fieldLabel="Itinerary"
                                    fieldName="itenary"
                                    fieldType={"file"}
                                    control={control}
                                    error={errors.itenary}
                                    onChange={}
                                /> */}
                                <ImageInput
                                    fieldName="itenary"
                                    fieldLabel="Itinerary"
                                    // imageHelperText={"512 * 512"}
                                    control={control}
                                    error={errors.itenary}
                                    type={'application/pdf'}
                                    changeImage={(e) => uploadFiles(e)}
                                    Icon={
                                        <div className='uploadIcon'><Image /></div>}
                                />
                            </Grid>}
                        </Grid>
                    </BorderBox>
                    {isArray(services) && services?.length > 0 && <div style={{ paddingTop: 20 }}>
                        <BorderBox title={"Add on Services"}>
                            <Grid container p={0} xs={12} paddingLeft={1}>
                                {services?.map((serv, index) => (
                                    <Grid xs={12} md={3} key={index} spacing={0.5}>
                                        <FormControlLabel
                                            style={{ fontSize: "12px" }}

                                            control={
                                                <Checkbox style={{ fontSize: 12 }} checked={serv?.checked} onChange={(e) => addAddOnService(e.target.checked, serv?.id)} name={serv?.id} />
                                            }
                                            label={<Typography style={{ fontSize: 12 }}>{serv?.name} (S${serv?.price})</Typography>}
                                        />
                                    </Grid>
                                ))}
                                {booking?.location_to?.type === "airport" && <Grid xs={3}>
                                    <FormControlLabel
                                        style={{ fontSize: "12px" }}
                                        control={
                                            <Checkbox style={{ fontSize: 12 }} checked={true} disabled name={"backageProtection"} />
                                        }
                                        label={<Typography style={{ fontSize: 12 }}>Baggage Protection</Typography>}
                                    />
                                </Grid>}
                            </Grid>
                        </BorderBox>
                    </div>}
                    {booking?.type === "point" && <div style={{ paddingTop: 20 }}>
                        <BorderBox title={"Arrival Information"}>
                            {/* {vehicleAvailability?.pickupType === "airport" &&
                                <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start'
                                        }}
                                    >
                                        <InputField
                                            fieldLabel="Flight No."
                                            fieldName="flight_no"
                                            control={control}
                                            error={errors.flight_no}
                                            required
                                        />
                                    </div>
                                </Grid>} */}
                            {(booking?.type === "point") && <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: 4,
                                        marginBottom: 4
                                    }}
                                >
                                    <span>
                                        <ErrorIcon style={{ width: 25, height: 25, marginRight: 10 }} />
                                    </span>
                                    <span>
                                        <Typography className='loctext' >Flight/Ferry arrival cannot be tracked without proper information!</Typography>
                                    </span>
                                </div>
                                <Grid container spacing={1}>
                                    {booking?.location_from?.type === "point" && <Grid item xs={12} md={4} sx={{ flexGrow: 1 }} >
                                        <DropdownInput
                                            fieldLabel="Arrival Method"
                                            fieldName="arrival_method"
                                            control={control}
                                            error={errors.arrival_method}
                                            changeValue={e => setMethods(e.target.value)}
                                        >
                                            <MenuItem disabled value="">
                                                Select
                                            </MenuItem>
                                            <MenuItem value="ferry">Cruise</MenuItem>
                                            <MenuItem value="others">Others</MenuItem>
                                        </DropdownInput>

                                    </Grid>}
                                    {booking?.location_from?.type === "airport" && <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                        <RadioGroup row style={{ paddingTop: 5 }} >
                                            <FormControlLabel
                                                value="international"
                                                control={<Radio
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setArival('international')
                                                        }
                                                    }}
                                                    checked={arival === "international"}
                                                //name="arival" {...register("arival")} value="international"   
                                                />}
                                                label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                            />
                                            {country !== "SG" && <FormControlLabel
                                                value="domestic"
                                                control={<Radio
                                                    name="arival"
                                                    //{...register("arival")}  
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setArival('domestic')
                                                        }
                                                    }}
                                                    checked={arival === "domestic"}
                                                />}
                                                label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                            />}
                                        </RadioGroup>
                                        <span style={{ fontSize: 10, color: 'red' }}>{errors.arival?.message}</span>
                                    </Grid>}

                                    {(booking?.location_from?.type === "airport" || vehicleAvailability?.pickupType === "airport") &&
                                        <Grid xs={12} md={1.5} item sx={{ flexGrow: 1 }}>
                                            <InputField
                                                fieldLabel="Flight No."
                                                fieldName="flight_no"
                                                control={control}
                                                error={errors.flight_no}
                                            />
                                        </Grid>}
                                    {(booking?.location_from?.type === "airport" || vehicleAvailability?.pickupType === "airport") &&
                                        <Grid xs={12} md={3}>
                                            <DateInput
                                                fieldLabel="Flight Date & Time"
                                                minDate={moment(booking?.pickupdate).toDate()}
                                                maxDate={moment(booking?.pickupdate).toDate()}
                                                fieldName="flight_date"
                                                control={control}
                                                fieldType="datetime"
                                                error={errors.flight_date}
                                                changeDate={(value) => setValue("flight_date", value)}
                                            />
                                        </Grid>}
                                    {(booking?.location_from?.type === "airport" || vehicleAvailability?.pickupType === "airport") && buffer &&
                                        <Grid item xs={12} md={3.3} sx={{ flexGrow: 1 }}>
                                            <DropdownInput
                                                fieldLabel="Buffer Time"
                                                fieldName="time_related"
                                                control={control}
                                                error={errors.time_related}
                                                values={buffer}
                                                changeValue={(e) => {
                                                    setValue('time_related', e.target?.value)
                                                    setBuffer(e.target?.value)
                                                }
                                                }
                                            >
                                                <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                                {flightArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                                    <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                                ))
                                                }
                                            </DropdownInput>
                                        </Grid>}
                                    {methods === "ferry" && <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                        <InputField
                                            fieldLabel="Name of Cruise"
                                            fieldName="cruise_no"
                                            control={control}
                                            error={errors.cruise_no}
                                        />
                                    </Grid>}
                                    {methods === "ferry" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                                        {/* <InputField
                                            fieldLabel="Time of disembarkment"
                                            fieldName="disembarkment"
                                            control={control}
                                            error={errors.disembarkment}
                                        /> */}
                                        <TimePicker
                                            fieldLabel="Time of Disembarkment"
                                            fieldName="disembarkment"
                                            control={control}
                                            error={errors.disembarkment}
                                            changeDate={(value) => setValue("disembarkment", moment(value, 'YYYY-MM-DD h:mm aa').format('h:mm aa'))}
                                        />
                                    </Grid>}
                                </Grid>
                            </>}
                        </BorderBox>
                    </div>}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingTop: 20,
                            paddingBottom: 100
                        }}
                    >
                        {bookingType === "PAL" && <Button
                            onClick={handleSubmit(addMoreBooking)}
                            variant="filled"
                            style={{
                                backgroundColor: '#333333',
                                borderRadius: 4,
                                width: 165,
                                marginRight: '3%',
                                padding: 5,
                                height: 30
                            }}
                            endIcon={<Add style={{ fontSize: 26, color: 'white' }} />}
                        >
                            <Typography className='buttonText'>ADD MORE BOOKING</Typography>
                        </Button>}
                        <Button
                            type="submit"
                            variant="filled"
                            style={{
                                backgroundColor: '#333333',
                                borderRadius: 4,
                                width: 165,
                                padding: 5,
                                height: 30
                            }}
                            endIcon={<KeyboardDoubleArrowRightIcon style={{ fontSize: 26, color: 'white' }} />}>
                            <Typography className='buttonText'>PROCEED</Typography>
                        </Button>
                    </div>
                </Box>
            </form>
        </Container>
    )
}

export default DetailsForm

